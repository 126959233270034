@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

* {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  margin: 10px auto 0;
}

.link {
  text-decoration: none;
  color: inherit;
}

.selected li {
  background-color: rgb(240, 240, 255);
}
